<template>

  <section v-if="data" class="org-card">

    <div class="org-card__intro">
      <h2 class="org-name">{{data.name}}</h2>
      <label class="org-type">{{data.organisationTypeID}}</label>
    </div>
    
    <div class="org-card__actions">
      <button class="org-card__action" @click="editOrg(data.organisationID)">
        <v-icon>edit</v-icon>
      </button>
      <button class="org-card__action" @click="deleteOrg()">
        <v-icon>delete</v-icon>
      </button>
    </div>

  </section>

</template>

<script>
export default {
  name: "OrganisationCard",

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    editOrg(id) {
      this.$router.push({ path: `/organisations/:${id}` })
    },
    deleteOrg() {
      this.$emit("deleteOrg")
      console.warn("Delete Org Triggered for:", this.data.name)
    }
  }
}
</script>

<style scoped lang='scss'>

.org-card {
  // padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  // padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 80px;

  &__intro {
    flex-grow: 1;
    margin: 16px 16px 8px;
  }

  &__actions {
    background-color: #E6E8F1;
    height: 100%;
    padding: 24px 16px;
  }

  &__action {
    margin: 0 8px 0 0;

    i {
      color: #67247c;
    }  
  }
}

.org-name {
  font-size: 16px;
}

.org-type {
  background-color: #f4f6d2;
  border: 1px solid #d6d8b0;
  width: auto;
  padding: 2px 4px;
  border-radius: 6px;
}

</style>
