<template>
  <section>

    <div class="button-wrapper">
      <v-btn
        type="button"
        depressed
        @click="addNew()"
        class="accent"
      >
        <v-icon small>add</v-icon>
        Add New Organisation
      </v-btn>

      <!-- <v-select class="filters"
        v-model="sortBy"
        :items="sortOptions"
        flat
        @change="filterOrganisations()" >
      </v-select> -->

      <v-select class="filters"
        v-if="organisationTypeFilters"
        v-model="selectedType"
        :items="organisationTypeFilters"
        placeholder="Filter by type"
        flat
        @change="filterOrganisations()"
        clearable >
      </v-select>
    </div>

    <organisation-card v-for="org in organisations" :key="org.organisationID" :data="org" @deleteOrg="deleteOrg(org.organisationID)" />

  </section>
</template>

<script>
import axios from "axios"
import OrganisationCard from "../../components/Organisations/OrganisationCard/OrganisationCard"

export default {
  name: "OrganisationList",

  components: {
    OrganisationCard
  },

  data: () => ({
    api: process.env.VUE_APP_ORGANISATIONAPI,
    organisations: null,
    dialog: false,
    organisationTypeFilters: [],
    selectedType: null,
    sortBy: "Alphabetical"
    // sortOptions: ['Order', 'Alphabetical']
  }),

  mounted() {
    this.getOrganisations()
    this.getOrganisationTypeFilters()
  },

  methods: {
    getOrganisations() {
      this.$store.commit("startLoading")
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/organisations?sort=alpha&pageSize=1000`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          console.log("Got orgs", response)

          this.organisations = response.data.body.Items
          this.$store.commit("completeLoading")
        })
        .catch(e => {
          console.error("Problem receiving orgs", e)
          this.$store.commit("completeLoading")
        })
      })
    },

    filterOrganisations() {
      if (!this.selectedType && this.sortBy == "Alphabetical") {
        this.getOrganisations()
      } else {
        this.$store.commit("startLoading")
        this.$getCurrentUserJwToken().subscribe((jwt) => {

          let selectedType = "";
          if (this.selectedType) {
            selectedType = `organisationTypeID=${this.selectedType}`
          } else {
            selectedType = ""
          }

          let sortBy = "";
          if (this.sortBy == "Alphabetical") {
            sortBy = "&sort=alpha"
          } else {
            sortBy = "&sort=position"
          }

          axios({
            method: "GET",
            url: `${this.api}/organisations?${selectedType}${sortBy}&pageSize=1000`,
            headers: { "Authorization": jwt }
          })
          .then(response => {
            console.log("Orgs filtered", response)

            this.organisations = response.data.body.Items
            this.$store.commit("completeLoading")
          })
          .catch(e => {
            console.error("Problem receiving orgs", e)
            this.$store.commit("completeLoading")
          })
        })
      }
    },

    getOrganisationTypeFilters() {
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        axios({
          method: "GET",
          url: `${this.api}/organisationtypes`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          response.data.body.Items.forEach(item => {
            this.organisationTypeFilters.push(item.organisationTypeID)
          })
        })
        .catch(e => {
          console.error("Problem receiving orgtypes", e)
        })
      })
    },

    addNew() {
      this.$router.push({ path: "/organisations/create" })
    },

    deleteOrg(id) {
      if (confirm("Are you sure you want to delete this Organisation? This is irreversible.")) {
        this.$store.commit("startLoading")
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          
          console.log("deleting:", id )

          axios({
            method: "delete",
            url: `${this.api}/organisations/${id}`,
            headers: { "Authorization": jwt }
          })
          
          .then(response => {
            console.log("Org deleted", response)

            this.getOrganisations();
            this.$store.commit("completeLoading")
          })
          .catch(e => {
            console.error("Problem deleting org", e)
            this.$store.commit("completeLoading")
          })
        })
      }
    }
  }
}
</script>

<style scoped lang='scss'>

  .button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .filters {
    max-width: 250px !important;
  }

  .list-complete-enter, .list-complete-leave-to {
    opacity: 0;
  }

  .list-complete-leave-active {
    position: absolute;
    width: calc(100% - (var(--body-gutter) * 2));
  }
</style>
