<template>

  <section v-if="data" class="org-type-card">

    <div class="org-type-card__intro">
      <h2 class="org-type-name">{{data.name}}</h2>
    </div>
    
    <div class="org-type-card__actions">
      <button class="org-type-card__action" @click="editOrgType(data.organisationTypeID)">
        <v-icon>edit</v-icon>
      </button>
      <button class="org-type-card__action" @click="deleteOrgType()">
        <v-icon>delete</v-icon>
      </button>
    </div>

  </section>

</template>

<script>
export default {
  name: "OrganisationTypeCard",

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  methods: {
    editOrgType(id) {
      this.$router.push({ path: `/organisationTypes/:${id}` })
    },
    deleteOrgType() {
      this.$emit("deleteOrgType")
      console.warn("Delete Org Triggered for:", this.data.organisationTypeID)
    }
  }
}
</script>

<style scoped lang='scss'>


  .org-type-card {
  // padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0;
  // padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 80px;

  &__intro {
    flex-grow: 1;
    margin: 16px 16px 8px;
  }

  &__actions {
    background-color: #E6E8F1;
    height: 100%;
    padding: 24px 16px;
  }

  &__action {
    margin: 0 8px 0 0;

    i {
      color: #67247c;
    }  
  }
}

.org-type-name {

  font-size: 16px;
}


</style>
