<template>
  <!-- <section class="main-body"> -->
  <section>
    <!-- <h1 class="subheading">Organisations Manager</h1> -->

    <v-tabs>

      <v-tab
        :ripple="{ class: 'accent--text' }">Organisations</v-tab>

      <v-tab-item
        px-0
        class="main-body">

        <organisation-list />
        
      </v-tab-item>

      <v-tab
        :ripple="{ class: 'accent--text' }">Organisation Types</v-tab>

      <v-tab-item
        px-0
        class="main-body">

        <organisation-types />
        
      </v-tab-item>

    </v-tabs>

  </section>
</template>

<script>
import OrganisationList from "../../components/Organisations/OrganisationList.vue"
import OrganisationTypes from "../../components/Organisations/OrganisationTypes.vue"

export default {
  name: "OrganisationsManager",

  components: {
    OrganisationList,
    OrganisationTypes
  }
}
</script>

<style scoped lang="scss">

</style>
