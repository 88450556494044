<template>
  <section>

    <v-btn
      type="button"
      depressed
      @click="addNew()"
      class="accent"
    >
      <v-icon small>add</v-icon>
      Add New Organisation Type
    </v-btn>

    <template v-if="organisationTypes">

      <organisation-type-card v-for="orgType in organisationTypes" :key="orgType.organisationTypeID" :data="orgType" @deleteOrgType="deleteOrgType(orgType.organisationTypeID)" />

    </template>
  
  </section>
</template>
<script>
import axios from "axios"
import OrganisationTypeCard from "../../components/Organisations/OrganisationTypeCard/OrganisationTypeCard"

export default {
  name: "OrganisationTypes",

  components: {
    OrganisationTypeCard
  },

  data: () => ({
    api: process.env.VUE_APP_ORGANISATIONAPI,
    organisationTypes: null
  }),

  mounted() {
    this.getOrganisationTypes()
  },

  methods: {
   
    getOrganisationTypes() {
      this.$store.commit("startLoading")
      this.$getCurrentUserJwToken().subscribe((jwt) => {
        // console.log('get orgtypes from:', `${this.api}/organisationtypes`, 'headers:', jwt )
        axios({
          method: "GET",
          url: `${this.api}/organisationtypes`,
          headers: { "Authorization": jwt }
        })
        .then(response => {
          // console.log('got orgtypes?', response)
          this.organisationTypes = response.data.body.Items
          this.$store.commit("completeLoading")
        })
        .catch(e => {
          console.error("Problem receiving orgtypes", e)
          this.$store.commit("completeLoading")
        })
      })
    },

    addNew() {
      this.$router.push({ path: "/organisationTypes/create" })
    },

    deleteOrgType(id) {
      if (confirm("Are you sure you want to delete this Organisation Type?  This is irreversible.")) {
        this.$store.commit("startLoading")
        this.$getCurrentUserJwToken().subscribe((jwt) => {
          
          console.log("deleting:", id )

          axios({
            method: "delete",
            url: `${this.api}/organisationtypes/${id}`,
            headers: { "Authorization": jwt }
          })
          
          .then(response => {
            console.log("deleted???", response)

            this.getOrganisationTypes()

          })
          .catch(e => {
            console.error("Problem deleting org", e)
          })
        })
      }
    }
  }
}
</script>

<style scoped lang='scss'>

</style>
